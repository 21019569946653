import { BASE_URL } from './api';

export const isEmailValid = (
  e,
  setAuthenticationError,
  setLoginEmail,
  setEmailError
) => {
  const newEmail = e.target.value;
  setAuthenticationError(null);
  setLoginEmail(newEmail);
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,}$/.test(newEmail)) {
    setEmailError('Invalid email address');
  } else {
    setEmailError(null);
  }
};

export const authenticateUser = async (
  endpoint,
  loginEmail,
  loginPassword,
  setAuthenticationError,
  setAuthenticationMessage,
  setIsAuthenticated,
  setCredentialError,
  setCredentialMessage
) => {
  try {
    const requestBody = {
      email: loginEmail,
      password: loginPassword,
    };

    const response = await fetch(`${BASE_URL}/user/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });

    const JSONResponse = await response.json();

    if (response.status === 200) {
      setAuthenticationMessage(`Welcome ${loginEmail}.`);
      setAuthenticationError(null);
      setIsAuthenticated(true);
      localStorage.setItem('token', JSONResponse.token);
    } else if (response.status === 201) {
      setAuthenticationError(null);
      setAuthenticationMessage(`You've successfully registered ${loginEmail}!`);
    } else if (response.status === 400) {
      setAuthenticationError(`${JSONResponse.message}`);
      setAuthenticationMessage(
        `You have not provided a valid email and password!`
      );
    } else if (response.status === 401) {
      setCredentialError(`${response.message}`);
      setCredentialMessage(`Incorrect email or password.`);
    } else if (response.status === 409) {
      setAuthenticationError(`${JSONResponse.message}`);
      setAuthenticationMessage(
        `This user already exists. Please try another email or log in now!`
      );
    } else {
      setAuthenticationError(`${JSONResponse.message}`);
      setAuthenticationMessage(
        `An unexpected error has occured. Please pass this on to your IT Team: ${JSONResponse.message}.`
      );
    }
  } catch (error) {
    setAuthenticationError(`${error}`);
    setAuthenticationMessage(
      `An unexpected error has occured. Please pass this on to your IT Team: ${error}.`
    );
  }
};
