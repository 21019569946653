import './Main.css';
import backgroundImage from '../../Assets/MayonVolcanoPhilippines.avif';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

export default function Home() {
  const navigate = useNavigate();

  return (
    <section
      className="hero"
      style={{ backgroundImage: `url(${backgroundImage})` }}
      aria-label="An image of Mayon volcano in the Philippines."
    >
      <div className="hero_content">
        <div>
          <h1 className="hero_title">Volcanoes of the world</h1>
          <p className="hero_subtitle">Your volcano finding partner</p>
        </div>
        <div className="home-button-wrapper">
          <Button
            variant="outline-dark"
            size="lg"
            onClick={() => {
              navigate('/Login');
            }}
          >
            Login
          </Button>

          <Button
            variant="outline-dark"
            size="lg"
            onClick={() => {
              navigate('/Register');
            }}
          >
            Register
          </Button>

          <Button
            variant="outline-dark"
            size="lg"
            onClick={() => {
              navigate('/Volcanoes');
            }}
          >
            Continue as guest
          </Button>
        </div>
      </div>
    </section>
  );
}
