import './Volcano.css';
import { useContext, useState, useEffect } from 'react';
import { MyContext } from '../Utils/myCtx';

export function VolcanoWeather(props) {
  const [weather, setWeather] = useState(null);
  const [error, setError] = useState(null);
  const latitude = props.latitude;
  const longitude = props.longitude;
  const API_KEY = 'f62180556e344faeb02100834242603';

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await fetch(
          `https://api.weatherapi.com/v1/current.json?key=${API_KEY}&q=${latitude},${longitude}`
        );
        const data = await response.json();
        setWeather(data);
      } catch (error) {}
    };
    fetchWeather();
  }, [latitude, longitude]);

  if (latitude === null || longitude === null) {
    return <></>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!weather) {
    return <div>Loading...</div>;
  }

  if (!weather.location) {
    return <div>No local weather information available</div>;
  }

  return (
    <div className="search-results-weather-widget">
      <h4>{weather.location.name}</h4>
      <h4>{weather.current.condition.text}</h4>
      <img src={weather.current.condition.icon} />
      <h4>{weather.current.temp_c}&deg;C</h4>
      <p>Feels like: {weather.current.feelslike_c}&deg;C</p>
    </div>
  );
}
