import './Main.css';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { MyContext } from '../Utils/myCtx';
import { AuthenticationModal } from '../Utils/AuthenticationModal';
import VolcanoIcon from '../../Assets/VolcanoIcon.webp';

//
// Material UI import
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
//

export default function Header() {
  const { authenticationMessage, setAuthenticationMessage } =
    useContext(MyContext);

  const navigate = useNavigate();
  const {
    isAuthenticated,
    setIsAuthenticated,
    setSearchedCountry,
    setSelectedCountry,
    showSearchModal,
    setCountryVolcanoResults,
    setTemporaryDistance,
    setTemporaryCountrySelection,
    setClientErrorMessage,
    setClientError,
  } = useContext(MyContext);

  useEffect(() => {
    const tidyUpResults = () => {
      setSearchedCountry(false);
      showSearchModal(true);
      setSelectedCountry([]);
      setCountryVolcanoResults();
      setTemporaryDistance(0);
      setTemporaryCountrySelection([]);
      setClientErrorMessage();
      setClientError();
    };
    tidyUpResults();
  }, [isAuthenticated]);

  const logOut = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    navigate('/');
    setAuthenticationMessage('You have now signed out - farewell!');
  };

  return (
    <header>
      {authenticationMessage ? <AuthenticationModal /> : <></>}

      <div className="nav-wrapper">
        <div className="nav-icon" id="icon" aria-label="A volcano themed icon.">
          <Link to="/" id="nav-icon-link">
            <img src={VolcanoIcon} alt="Volcano Icon" />
          </Link>
        </div>

        <div className="nav-button-container">
          <Button
            variant="outline-dark"
            className="nav-button"
            onClick={() => {
              navigate('/');
            }}
          >
            Home
          </Button>

          <Button
            variant="outline-dark"
            className="nav-button"
            onClick={() => {
              navigate('/Volcanoes');
            }}
          >
            Volcano Search
          </Button>

          <div aria-live="polite">
            {isAuthenticated ? (
              <Button
                variant="success"
                className="nav-button"
                onClick={() => {
                  logOut();
                }}
              >
                Log out
              </Button>
            ) : (
              <>
                <Button
                  variant="outline-warning"
                  className="nav-button"
                  onClick={() => {
                    navigate('/Login');
                  }}
                >
                  Log in
                </Button>
                <Button
                  variant="outline-warning"
                  className="nav-button"
                  onClick={() => {
                    navigate('/Register');
                  }}
                >
                  Register
                </Button>
              </>
            )}
          </div>
          {/*
          <Box>
            <Tabs
              value={tab}
              onChange={changeTab}
              textColor="primary"
              indicatorColor="primary"
              role="navigation"
              sx={{
                '& .MuiTab-root': {
                  color: '  #ffa500',
                  minWidth: '120px',
                  margin: '0 2px',
                  padding: '10px 20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                '& .Mui-selected': {
                  color: 'primary',
                },
                '& .MuiTabs-indicator': {
                  backgroundColor: 'primary.main',
                },
              }}
            >
              <Tab
                label="Home"
                onClick={() => {
                  navigate('/');
                }}
              />
              <Tab
                color="white"
                label="Projects"
                onClick={() => {
                  navigate('/Projects');
                }}
              />
              <Tab
                label="Certificates"
                onClick={() => {
                  navigate('/Certificates');
                }}
              />
              <Tab
                label="Contact"
                onClick={() => {
                  navigate('/Contact');
                }}
              />
            </Tabs>
          </Box>
          */}
        </div>
      </div>
    </header>
  );
}
