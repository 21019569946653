import './Volcano.css';
import MyMap from './VolcanoMap';
import { Form, FormGroup, Col, Input, Container, Row } from 'reactstrap';
import { Rating } from 'react-simple-star-rating';
import { Button } from 'reactstrap';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../Utils/myCtx';
import { useFetchSelectedVolcano } from '../Utils/api';
import { useFetchVolcanoRatings } from '../Utils/api';
import { postRating } from '../Utils/socialInteraction';
import { VolcanoPopulationGraph } from './VolcanoPopulationGraph';
import { VolcanoRatings } from './VolcanoRatings';
import { VolcanoWeather } from './VolcanoWeather';

export default function VolcanoDetails() {
  const navigate = useNavigate();

  const {
    volcanoId,
    isAuthenticated,
    setClientError,
    clientErrorMessage,
    setClientErrorMessage,
    newRating,
    fetchNewRatings,
    clientSocialError,
    setClientSocialError,
    clientSocialNotification,
    setClientSocialNotification,
  } = useContext(MyContext);

  const { volcanoData } = useFetchSelectedVolcano(
    volcanoId,
    isAuthenticated,
    setClientError,
    setClientErrorMessage
  );

  return (
    <div className="search-result-body">
      <div className="search-result-container">
        <div className="search-results-text">
          <div className="search-results-text-container">
            <h2> {volcanoData.name}</h2>
            <br />
            <p>Country: {volcanoData.country}</p>
            <p>Region: {volcanoData.region}</p>
            <p>Subregion: {volcanoData.subregion}</p>
            <p>Last Eruption: {volcanoData.last_eruption}</p>
            <p>Summit (m): {volcanoData.summit}</p>
            <p>Elevation (ft): {volcanoData.elevation}</p>
            <p>Latitude: {volcanoData.latitude}</p>
            <p>Longitude: {volcanoData.longitude}</p>
            <br />
          </div>
          <div className="search-results-weather-container">
            <VolcanoWeather
              volcanoData={volcanoData}
              latitude={volcanoData.latitude}
              longitude={volcanoData.longitude}
            />
          </div>
        </div>
        <div className="search-results-static">
          <div
            className="search-results-map"
            aria-label="Map showing location of selected volcano"
          >
            <MyMap
              latitude={volcanoData.latitude}
              longitude={volcanoData.longitude}
            />
          </div>
          <div className="search-results-graph-container">
            <div
              className="search-results-graph"
              aria-label="Graph showing the population density from the selected volcano"
            >
              <VolcanoPopulationGraph volcanoData={volcanoData} />
            </div>
          </div>
        </div>
        <>
          <VolcanoRatings />
        </>
      </div>
    </div>
  );
}
