import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './components/Main/Header';
import Footer from './components/Main/Footer';
import Home from './components/Main/Home';
import VolcanoList from './components/Volcano/VolcanoList';
import VolcanoDetails from './components/Volcano/VolcanoDetails';
import Login from './components/Auth/Login';
import Register from './components/Auth/Register';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { MyContext } from './components/Utils/myCtx';
import { useFetchCountries } from './components/Utils/api';
import { NotificationModal } from './components/Utils/NotificationModal';
import { SocialNotificationModal } from './components/Utils/SocialNotificationModal';

function App() {
  const [notificationModal, showNotificationModal] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedDistance, setSelectedDistance] = useState(0);
  const [distances] = useState([5, 10, 30, 100]);
  const [volcanoId, setVolcanoId] = useState([]);
  const [volcano, setVolcano] = useState({});
  const [temporaryCountrySelection, setTemporaryCountrySelection] = useState(
    []
  );
  const [temporaryDistance, setTemporaryDistance] = useState(0);
  const [searchModal, showSearchModal] = useState(true);
  const [emailError, setEmailError] = useState(null);
  const [authenticationError, setAuthenticationError] = useState(null);
  const [authenticationMessage, setAuthenticationMessage] = useState(null);
  const [credentialError, setCredentialError] = useState(null);
  const [credentialMessage, setCredentialMessage] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [agGridCurrentPage, setAgGridCurrentPage] = useState();
  const [countryVolcanoResults, setCountryVolcanoResults] = useState();
  const [searchedCountry, setSearchedCountry] = useState(false);
  const [clientError, setClientError] = useState(null);
  const [clientErrorMessage, setClientErrorMessage] = useState(null);
  const [showAuthenticationModal, setShowAuthenticationModal] = useState(false);
  const [clientSocialNotification, setClientSocialNotification] =
    useState(null);
  const [clientSocialError, setClientSocialError] = useState(null);
  const [clientDataDisclaimer, showDataDisclaimer] = useState();
  const [editExistingRating, setEditExistingRating] = useState(false);

  const { countryData } = useFetchCountries(
    setClientError,
    setClientErrorMessage,
    showNotificationModal
  );
  const dataDisclaimer =
    'Data disclaimer: Email addresses and password hashes are deleted regularly. \
  No temporarily retained information is or will ever be for further use. \
  Passwords are never stored in plain text, they are hashed numerous times and compared to authenticate.';

  useEffect(() => {
    if (countryData !== null) {
      setCountries(countryData);
    }
  }, [countryData]);

  return (
    <BrowserRouter>
      <div className="application-parent">
        <MyContext.Provider
          value={{
            countries,
            setCountries,
            selectedCountry,
            setSelectedCountry,
            countryVolcanoResults,
            setCountryVolcanoResults,
            volcanoId,
            setVolcanoId,
            volcano,
            setVolcano,
            selectedDistance,
            setSelectedDistance,
            distances,
            temporaryCountrySelection,
            setTemporaryCountrySelection,
            temporaryDistance,
            setTemporaryDistance,
            searchedCountry,
            setSearchedCountry,
            searchModal,
            showSearchModal,
            showAuthenticationModal,
            setShowAuthenticationModal,
            notificationModal,
            showNotificationModal,
            emailError,
            setEmailError,
            authenticationError,
            setAuthenticationError,
            authenticationMessage,
            setAuthenticationMessage,
            isAuthenticated,
            setIsAuthenticated,
            agGridCurrentPage,
            setAgGridCurrentPage,
            credentialError,
            setCredentialError,
            credentialMessage,
            setCredentialMessage,
            setClientError,
            clientError,
            clientErrorMessage,
            setClientErrorMessage,
            clientSocialNotification,
            setClientSocialNotification,
            clientSocialError,
            setClientSocialError,
            clientDataDisclaimer,
            showDataDisclaimer,
            dataDisclaimer,
            editExistingRating,
            setEditExistingRating,
          }}
        >
          <div className="Header">
            <Header />
          </div>
          {clientErrorMessage ? <NotificationModal /> : <></>}
          {clientSocialNotification ? <SocialNotificationModal /> : <></>}
          <div className="Body">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Volcanoes" element={<VolcanoList />} />
              <Route path="/login" element={<Login />} />
              <Route path="/Register" element={<Register />} />
              <Route path="/VolcanoDetails" element={<VolcanoDetails />} />
            </Routes>
          </div>
        </MyContext.Provider>
        <div className="Footer">
          <Footer />
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
