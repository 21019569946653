import { useEffect, useState } from 'react';
export const BASE_URL = `https://api.whoisalexm.com`;

export const useFetchCountries = (setClientError, setClientErrorMessage) => {
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      setClientError(null);
      setClientErrorMessage(null);
      try {
        const response = await fetch(`${BASE_URL}/countries`);
        const json = await response.json();
        try {
          setCountryData(json);
        } catch (error) {
          setClientError(`${error.message}`);
          setClientErrorMessage(
            `There was an error when setting the country data: ${error.message}`
          );
          setCountryData(null);
        }
        if (response.status === 400) {
          setClientError(`${json.message}`);
          setClientErrorMessage(
            `The query parameters used to fetch the countries was not correct: ${json.message}`
          );
          setCountryData(null);
        } else if (response.status === 404) {
          setClientError(`${json.message}`);
          setClientErrorMessage(
            `There was an error when fetching the countries: ${json.status} - ${json.message} Please restart your session.`
          );
          setCountryData(null);
        }
      } catch (error) {
        setClientError(`${error}`);
        setClientErrorMessage(
          `There was an issue fetching the intial list of countries: ${error.message}`
        );
        setCountryData(null);
      }
    };
    getCountries();
  }, [BASE_URL]);
  return { countryData };
};

export const fetchCountriesVolcanoes = async (
  selectedCountry,
  selectedDistance,
  setCountryVolcanoResults,
  setSearchedCountry,
  searchedCountry,
  setClientError,
  setClientErrorMessage
) => {
  try {
    if (
      !selectedCountry ||
      selectedCountry.length < 1 ||
      selectedCountry === ''
    ) {
      setSearchedCountry(false);
      setCountryVolcanoResults([]);
      return;
    }

    const checkDistance = selectedDistance => {
      if (selectedDistance > 0) {
        return `&populatedWithin=${selectedDistance}km`;
      } else {
        return '';
      }
    };

    const response = await fetch(
      `${BASE_URL}/volcanoes?country=${selectedCountry}${checkDistance(
        selectedDistance
      )}`
    );

    const json = await response.json();

    try {
      setCountryVolcanoResults(
        json.map(volcano => ({
          id: volcano.id,
          name: volcano.name,
          region: volcano.region,
          subregion: volcano.subregion,
        }))
      );
      setSearchedCountry(true);
    } catch (error) {
      setClientError(`${error.message}`);
      setClientErrorMessage(
        `There was an error when setting the country volcanoes data: ${error.message}`
      );
      setCountryVolcanoResults([]);
      setSearchedCountry(false);
    }

    if (response.status === 400) {
      setClientError(`${json.message}`);
      setClientErrorMessage(
        `The query parameters used to fetch the volcanoes were not correct: ${json.message}`
      );
      setCountryVolcanoResults([]);
    } else if (response.status === 401) {
      setSearchedCountry(false);
      setClientError(`${json.message}`);
      setClientErrorMessage(
        `There was an authentication error when fetching your country: ${json.status} - ${json.message}.`
      );
    } else if (response.status === 404) {
      setClientError(`${json.message}`);
      setClientErrorMessage(
        `There was an error when fetching the volcanoes: ${json.status} - ${json.message}`
      );
      setCountryVolcanoResults([]);
    }
  } catch (error) {
    setClientError(`${error.message}`);
    setClientErrorMessage(
      `There was an issue fetching the list of volcanoes: ${error.message}`
    );
    setCountryVolcanoResults([]);
    setSearchedCountry(false);
  }
};

export const useFetchSelectedVolcano = (
  volcanoId,
  isAuthenticated,
  setClientError,
  setClientErrorMessage
) => {
  const [volcanoData, setVolcanoData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [volcanoRatings, setVolcanoRatings] = useState([]);

  useEffect(() => {
    if (loading === true) {
      return;
    }
    const getVolcano = async () => {
      setLoading(true);
      setClientError(null);
      setClientErrorMessage(null);
      try {
        const headers = isAuthenticated
          ? { Authorization: `Bearer ${localStorage.getItem('token')}` }
          : {};

        const response = await fetch(`${BASE_URL}/volcano/${volcanoId}`, {
          method: `GET`,
          headers: headers,
        });

        const json = await response.json();

        try {
          setVolcanoData(json);
        } catch (error) {
          setClientError(`${error.message}`);
          setClientErrorMessage(
            `There was an error when setting the volcano data: ${error.message}`
          );
          setVolcanoData(null);
        }
        if (response.status === 400) {
          setClientError(`${json.message}`);
          setClientErrorMessage(
            `The query parameters used to fetch the volcano were not correct: ${json.message}`
          );
          setVolcanoData(null);
        } else if (response.status === 401) {
          setClientError(`${json.message}`);
          setClientErrorMessage(
            `There was an error with your authentication: ${json.message}. Please login again to continue.`
          );
          localStorage.clear();
        } else if (response.status === 404) {
          setClientError(`${json.message}`);
          setClientErrorMessage(
            `There was an error when fetching the selected volcano: ${json.status} - ${json.message} Please restart your session.`
          );
          setVolcanoData(null);
        }

        const fetchVolcanoRatings = await fetch(
          `${BASE_URL}/volcano/${volcanoId}/rating`,
          {
            method: `GET`,
            headers: headers,
          }
        );

        const ratingsJson = await fetchVolcanoRatings.json();
        try {
          setVolcanoRatings(ratingsJson);
        } catch (error) {
          setClientError(`${error.message}`);
          setClientErrorMessage(
            `There was an error when fetching the volcano data: ${error.message}`
          );
          setVolcanoRatings(null);
        }
      } catch (error) {
        setClientError(`${error}`);
        setClientErrorMessage(
          `There was an issue fetching the selected volcano: ${error.message}`
        );
        setVolcanoData(null);
        setVolcanoRatings(null);
      } finally {
        setLoading(false);
      }
    };
    getVolcano();
  }, [volcanoId]);
  return { volcanoData, volcanoRatings };
};

export const useFetchVolcanoRatings = (
  volcanoId,
  newRating,
  setClientError,
  setClientErrorMessage
) => {
  const [loading, setLoading] = useState(false);
  const [volcanoRatings, setVolcanoRatings] = useState([]);

  const getVolcanoRatings = async () => {
    if (loading === true) {
      return;
    }
    setLoading(true);
    setClientError(null);
    setClientErrorMessage(null);
    try {
      const fetchVolcanoRatings = await fetch(
        `${BASE_URL}/volcano/${volcanoId}/rating`,
        {
          method: `GET`,
          headers: {},
        }
      );

      const ratingsJson = await fetchVolcanoRatings.json();
      try {
        setVolcanoRatings(ratingsJson);
      } catch (error) {
        setClientError(`${error.message}`);
        setClientErrorMessage(
          `There was an error when fetching the volcano ratings: ${error.message}`
        );
        setVolcanoRatings(null);
      }
    } catch (error) {
      setClientError(`${error}`);
      setClientErrorMessage(
        `There was an issue fetching the selected volcano ratings: ${error.message}`
      );
      setVolcanoRatings(null);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getVolcanoRatings();
  }, [newRating]);
  return { volcanoRatings, getVolcanoRatings };
};
