import { MyContext } from './myCtx';
import { useContext } from 'react';
import { Button, Modal, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Auth/Admin.css';

export function AuthenticationModal() {
  const {
    setShowAuthenticationModal,
    authenticationMessage,
    setAuthenticationMessage,
    authenticationError,
    isAuthenticated,
    clientDataDisclaimer,
    showDataDisclaimer,
    dataDisclaimer,
  } = useContext(MyContext);

  const navigate = useNavigate();

  const clearErrors = () => {
    setShowAuthenticationModal(false);
    setAuthenticationMessage(null);
    showDataDisclaimer(false);
  };

  return (
    <div>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={authenticationMessage}
        onHide={clearErrors}
        animation={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          {authenticationError ? (
            <Modal.Title>
              <Badge bg="danger">Oh no!</Badge>
            </Modal.Title>
          ) : isAuthenticated ? (
            <Modal.Title>
              <Badge bg="success">Success!</Badge>
            </Modal.Title>
          ) : clientDataDisclaimer ? (
            <Modal.Title>
              <Badge outline color="primary">
                You're in good hands.
              </Badge>
            </Modal.Title>
          ) : (
            <Modal.Title>
              <Badge bg="success">Success!</Badge>
            </Modal.Title>
          )}
        </Modal.Header>

        <Modal.Body>
          <>
            {authenticationError ? (
              <p>{authenticationMessage}</p>
            ) : isAuthenticated ? (
              <p>Start searching for volcanoes now!</p>
            ) : (
              <p>{authenticationMessage}</p>
            )}
          </>
        </Modal.Body>

        <Modal.Footer>
          {authenticationError ? (
            <div className="authenticationModal-button-container">
              <Button
                variant="outline-success"
                className="login-button btn-lg"
                type="submit"
                onClick={() => {
                  clearErrors();
                  navigate('/Login');
                }}
              >
                Log in now!
              </Button>
              <Button
                variant="dark"
                className="login-button btn-lg"
                onClick={() => {
                  clearErrors();
                }}
              >
                Try again.
              </Button>
            </div>
          ) : isAuthenticated ? (
            <Button
              variant="dark"
              className="login-button btn-lg"
              type="submit"
              onClick={() => {
                clearErrors();
                navigate('/Volcanoes');
              }}
            >
              Search volcanoes!
            </Button>
          ) : clientDataDisclaimer ? (
            <Button
              variant="dark"
              className="login-button btn-lg"
              type="submit"
              onClick={() => {
                clearErrors();
              }}
            >
              Close
            </Button>
          ) : (
            <div className="authenticationModal-button-container">
              <Button
                variant="outline-success"
                className="login-button btn-lg"
                type="submit"
                onClick={() => {
                  clearErrors();
                  navigate('/Login');
                }}
              >
                Log in now!
              </Button>
              <Button
                variant="outline-dark"
                className="login-button btn-lg"
                onClick={() => {
                  setAuthenticationMessage(dataDisclaimer);
                  showDataDisclaimer(true);
                }}
              >
                View Data Disclaimer
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
