import { useContext } from 'react';
import { MyContext } from '../Utils/myCtx';
import { Chart } from 'react-chartjs-2';
import './Volcano.css';
import 'chart.js/auto';

export function VolcanoPopulationGraph({ volcanoData }) {
  const { distances } = useContext(MyContext);
  return (
    <Chart
      type="bar"
      data={{
        labels: distances.map(distance => `${distance} km`),
        datasets: [
          {
            label: `Population for ${volcanoData.name}`,
            data: [
              volcanoData.population_5km,
              volcanoData.population_10km,
              volcanoData.population_30km,
              volcanoData.population_100km,
            ],
            backgroundColor: ['#FF5733', '#E74C3C', '#F39C12', '#F1C40F'],
            borderColor: ['#FF5733', '#E74C3C', '#F39C12', '#F1C40F'],
            borderWidth: 0.5,
            barPercentage: 1.0,
          },
        ],
      }}
      height={300}
      options={{
        indexAxis: 'y',
        scales: {
          y: {
            grid: { color: 'rgb(0,0,0)' },
            ticks: { color: 'rgb(0,0,0)' },
            title: {
              display: true,
              text: `Kilometres from volcano.`,
              color: 'rgb(0,0,0)',
              font: {
                family: `'Inter'`,
                // size: `1rem`,
                // weight: `400`,
              },
            },
          },
          x: {
            grid: { color: 'rgb(0,0,0)' },
            ticks: { color: 'rgb(0,0,0)' },
            title: {
              display: true,
              text: 'Number of people residing within radius.',
              color: 'rgb(0,0,0)',
              font: {
                family: `'Inter'`,
                // size: `1rem`,
              },
            },
          },
        },
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: `Population density for ${volcanoData.name}`,
            color: 'rgb(0,0,0)',
            font: {
              family: `'Inter'`,
            },
          },
        },
        responsive: true,
        layout: {
          padding: {
            // left: 15,
            // bottom: 15,
            // right: 15,
          },
        },
      }}
    />
  );
}
