import './Volcano.css';
import { Form, FormGroup, Col, Input, Container, Row } from 'reactstrap';
import { Rating } from 'react-simple-star-rating';
import { Button } from 'reactstrap';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MyContext } from '../Utils/myCtx';
import { useFetchVolcanoRatings } from '../Utils/api';
import { postRating } from '../Utils/socialInteraction';

export function VolcanoRatings() {
  const navigate = useNavigate();

  const {
    volcanoId,
    isAuthenticated,
    setClientError,
    clientErrorMessage,
    setClientErrorMessage,
    newRating,
    fetchNewRatings,
    clientSocialError,
    setClientSocialError,
    clientSocialNotification,
    setClientSocialNotification,
    authorisedEmail,
    editExistingRating,
    setEditExistingRating,
  } = useContext(MyContext);

  const [inputComment, setInputComment] = useState('');
  const [inputRating, setInputRating] = useState(5);

  const clearInput = () => {
    setInputComment('');
    setInputRating(5);
  };

  const inputChanged = e => setInputComment(e.target.value);
  const storeRating = rate => {
    setInputRating(rate);
  };

  const { volcanoRatings, getVolcanoRatings } = useFetchVolcanoRatings(
    volcanoId,
    newRating,
    setClientError,
    setClientErrorMessage
  );

  const submitRating = async () => {
    await postRating(
      volcanoId,
      isAuthenticated,
      setClientSocialError,
      setClientSocialNotification,
      inputRating,
      inputComment,
      editExistingRating,
      setEditExistingRating,
      clearInput
    );
    getVolcanoRatings();
  };

  return (
    <div className="search-results-reviews">
      <Form>
        <FormGroup row>
          <h3>Leave a review:</h3>
          <Rating
            onClick={storeRating}
            initialValue={5}
            iconsCount={5}
            transition="true"
            allowHover="true"
          />
          <Col sm={100}>
            <Input
              id="userCommentTextInput"
              name="text"
              type="textarea"
              value={inputComment}
              onChange={inputChanged}
              rows={4}
              style={{ marginTop: '10px', resize: 'none' }}
              placeholder="Write your review here..."
            />
          </Col>
          <div className="comment-button-container">
            <Button
              color="outline-dark"
              className="comment-button"
              onClick={clearInput}
            >
              Clear
            </Button>
            <Button
              color="outline-success"
              className="comment-button"
              onClick={submitRating}
            >
              {editExistingRating === true ? 'Overwrite' : 'Submit'}
            </Button>
          </div>
        </FormGroup>
      </Form>
      <div className="reviews-box">
        <Container className="reviews-container">
          <Row>
            {volcanoRatings?.length > 0 ? (
              volcanoRatings.map((rating, index) => (
                <Col key={rating.email} xs={12}>
                  <p>
                    <Rating
                      initialValue={rating.star_rating}
                      iconsCount={5}
                      readonly
                      size={20}
                    />
                    <br />
                    <i className="result-date">
                      {rating.created_at.slice(0, 10)}
                    </i>
                    <br />
                    {rating.comment}
                  </p>
                  {index < volcanoRatings.length - 1 && <hr />}
                </Col>
              ))
            ) : (
              <p>Be the first to review this volcano!</p>
            )}
          </Row>
        </Container>
      </div>
      <Button
        color="dark"
        className="search-results-text-button"
        onClick={() => {
          navigate('/Volcanoes');
        }}
      >
        Back
      </Button>
    </div>
  );
}
