import { BASE_URL } from './api';

export const postRating = async (
  volcanoId,
  isAuthenticated,
  setClientSocialError,
  setClientSocialNotification,
  starRating,
  ratingComment,
  editExistingRating,
  setEditExistingRating,
  clearInput
) => {
  try {
    const date = new Date().toLocaleString('en-AU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    });

    const requestBody = {
      star_rating: starRating,
      comment: ratingComment,
      created_at: date,
    };

    const headers = isAuthenticated
      ? {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        }
      : { 'Content-Type': 'application/json' };

    const method = editExistingRating ? 'PUT' : 'POST';

    const response = await fetch(`${BASE_URL}/volcano/${volcanoId}/rating`, {
      method: method,
      headers,
      body: JSON.stringify(requestBody),
    });

    const json = await response.json();

    if (response.status === 200) {
      setEditExistingRating(false);
      setClientSocialNotification(null);
      clearInput();
      return json;
    } else if (response.status === 400) {
      setClientSocialError(`${json.message}`);
      setClientSocialNotification(
        `There is an issue with your request body. ${json.message}`
      );
    } else if (response.status === 401) {
      setClientSocialError(`${json.message}`);
      setClientSocialNotification(
        `Please log in or register to provide a rating.`
      );
    } else if (response.status === 403) {
      setClientSocialError(`${json.message}`);
      setClientSocialNotification(
        `You've already provided a review for this volcano, select overwrite to overwrite your last review.`
      );
      setEditExistingRating(true);
    } else if (response.status === 404) {
      setClientSocialError(`${json.message}`);
      setClientSocialNotification(
        `There was an error when fetching your country: ${json.status} - ${json.message}.`
      );
    }
  } catch (error) {
    setClientSocialError(`${error.message}`);
    setClientSocialNotification(`${error.message}`);
  }
};
