import './Admin.css';
import { Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { isEmailValid, authenticateUser } from '../Utils/authentication';
import { MyContext } from '../Utils/myCtx';
import { AuthenticationModal } from '../Utils/AuthenticationModal';
import { useState, useContext } from 'react';

export default function Register() {
  const navigate = useNavigate();

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const {
    authenticationError,
    setAuthenticationError,
    setCredentialError,
    setCredentialMessage,
    emailError,
    setEmailError,
    authenticationMessage,
    setAuthenticationMessage,
    setIsAuthenticated,
    showDataDisclaimer,
    dataDisclaimer,
  } = useContext(MyContext);

  return (
    <div className="registration-form-container">
      <Form
        className="registration-form"
        onSubmit={e => {
          e.preventDefault();
        }}
      >
        {authenticationMessage ? <AuthenticationModal /> : <></>}

        {loginEmail ? (
          <h1 className="registration-greeting">Hello {loginEmail}!</h1>
        ) : (
          <h1 className="registration-greeting">Register now!</h1>
        )}

        <FormGroup>
          <Label for="Email">Email</Label>

          <Input
            invalid={emailError !== null || authenticationError !== null}
            valid={loginEmail !== '' && authenticationError === null}
            type="email"
            name="email"
            id="Email"
            placeholder="Enter email"
            value={loginEmail}
            onChange={e => {
              isEmailValid(
                e,
                setAuthenticationError,
                setLoginEmail,
                setEmailError
              );
            }}
          />

          {!emailError && loginEmail && (
            <FormFeedback valid>Valid email address</FormFeedback>
          )}
          {
            <FormFeedback>
              {emailError ||
                (authenticationError &&
                  'Email / Password combination invalid.')}
            </FormFeedback>
          }
        </FormGroup>

        <FormGroup>
          <Label for="Password">Password</Label>
          <Input
            invalid={authenticationError !== null}
            type="password"
            name="password"
            id="Password"
            placeholder="Enter password"
            value={loginPassword}
            onChange={e => {
              setAuthenticationError(null);
              setLoginPassword(e.target.value);
            }}
          />

          {
            <FormFeedback>
              {emailError ||
                (authenticationError &&
                  'Email / Password combination invalid.')}
            </FormFeedback>
          }
        </FormGroup>

        <div className="registration-button-wrapper">
          <Button
            variant="dark"
            className="login-button btn-lg"
            disabled={authenticationError !== null || emailError !== null}
            type="submit"
            onClick={() => {
              authenticateUser(
                `register`,
                loginEmail,
                loginPassword,
                setAuthenticationError,
                setAuthenticationMessage,
                setIsAuthenticated,
                setCredentialError,
                setCredentialMessage
              );
            }}
          >
            Register now!
          </Button>
        </div>
        <div className="registration-button-wrapper">
          <Button
            variant="secondary"
            className="registration-button btn-lg"
            onClick={() => {
              navigate('/');
            }}
          >
            Back to home
          </Button>
        </div>
        <div className="data-disclaimer">
          <Button
            variant="outline-dark"
            size="sm"
            onClick={() => {
              setAuthenticationMessage(dataDisclaimer);
              showDataDisclaimer(true);
            }}
          >
            Data Disclaimer
          </Button>
        </div>
      </Form>
    </div>
  );
}
