import { MyContext } from './myCtx';
import { useContext } from 'react';
import { Button, Modal, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Auth/Admin.css';
import { submitRating } from '../Volcano/VolcanoRatings';

export function SocialNotificationModal() {
  const navigate = useNavigate();

  const {
    clientSocialError,
    setClientSocialError,
    clientSocialNotification,
    setClientSocialNotification,
    isAuthenticated,
    setIsAuthenticated,
    setEditExistingRating,
  } = useContext(MyContext);

  const clearErrors = () => {
    setClientSocialNotification(null);
  };

  return (
    <div>
      <Modal
        size="md"
        centered
        show={clientSocialNotification}
        onHide={clearErrors}
        animation={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {clientSocialError ? (
              <Badge bg="danger">Oh no!</Badge>
            ) : (
              <Badge bg="success">Success!</Badge>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{clientSocialNotification}</p>
          <p>{clientSocialError.message}</p>
        </Modal.Body>
        <Modal.Footer>
          {!isAuthenticated ? (
            <>
              <Button
                variant="outline-success"
                className="notification-button btn-lg"
                onClick={() => {
                  setClientSocialNotification(null);
                  navigate('/Login');
                }}
              >
                Log in now!
              </Button>
              <Button
                variant="outline-success"
                className="notification-button btn-lg"
                onClick={() => {
                  setClientSocialNotification(null);
                  navigate('/Register');
                }}
              >
                Register
              </Button>
            </>
          ) : (
            <></>
          )}
          <Button
            variant="dark"
            className="notification-button btn-lg"
            onClick={clearErrors}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
