import './Main.css';
import { Button, Modal, Badge } from 'react-bootstrap';

export default function Footer() {
  return (
    <footer className="footer">
      <Button
        variant="outline-danger"
        href="https://www.AlexMcConachie.com"
        target="_blank"
      >
        www.AlexMcConachie.com
      </Button>
    </footer>
  );
}
