import { MyContext } from '../Utils/myCtx';
import { useContext } from 'react';
import { Button, Modal, Dropdown, DropdownButton } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import './Volcano.css';
import { fetchCountriesVolcanoes } from '../Utils/api';

export function VolcanoSearchBox() {
  const {
    countries,
    setCountryVolcanoResults,
    selectedCountry,
    setSelectedCountry,
    selectedDistance,
    setSelectedDistance,
    distances,
    searchedCountry,
    setSearchedCountry,
    searchModal,
    showSearchModal,
    temporaryCountrySelection,
    temporaryDistance,
    setTemporaryDistance,
    setTemporaryCountrySelection,
    setClientError,
    setClientErrorMessage,
  } = useContext(MyContext);

  const searchSelectedCountry = () => {
    if (temporaryCountrySelection !== selectedCountry) {
      setSelectedCountry(temporaryCountrySelection);
    }
    if (temporaryDistance !== selectedDistance) {
      setSelectedDistance(temporaryDistance);
    }
    fetchCountriesVolcanoes(
      temporaryCountrySelection,
      temporaryDistance,
      setCountryVolcanoResults,
      setSearchedCountry,
      searchedCountry,
      setClientError,
      setClientErrorMessage
    );
    showSearchModal(false);
  };

  return (
    <div>
      <>
        <Button
          variant={searchedCountry ? 'outline-dark' : 'danger'}
          className="login-button btn-lg"
          onClick={() => showSearchModal(true)}
          disabled={countries === null}
          aria-live="polite"
        >
          Select Country
        </Button>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={searchModal}
          onHide={() => showSearchModal(false)}
          animation={true}
          backdrop="static"
          keyboard={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Select a country and a distance to display volcanoes.
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Typeahead
              id="basic-typeahead-single"
              onChange={setTemporaryCountrySelection}
              options={countries ? countries : []}
              placeholder="Choose a Country ..."
              selected={temporaryCountrySelection}
              clearButton
              aria-live="polite"
            />

            <p>Select a populated within distance...</p>

            <DropdownButton
              id="dropdown-item-button"
              variant="dark"
              title={
                temporaryDistance === 0 ? 'All' : `${temporaryDistance} kms`
              }
              drop="down"
              direction="down"
              aria-live="polite"
            >
              <Dropdown.Item
                action="true"
                onClick={() => setTemporaryDistance(0)}
              >
                All
              </Dropdown.Item>

              {distances.map(distance => (
                <Dropdown.Item
                  key={distance}
                  action="true"
                  onClick={() => setTemporaryDistance(distance)}
                >
                  {`${distance} kms`}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="outline-dark"
              onClick={() => showSearchModal(false)}
            >
              Close
            </Button>

            <Button variant="success" onClick={searchSelectedCountry}>
              Search
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </div>
  );
}
