import { Map, Marker } from 'pigeon-maps';
import { osm } from 'pigeon-maps/providers';

export default function MyMap(props) {
  const lat = parseFloat(props.latitude);
  const long = parseFloat(props.longitude);

  if (props.latitude === undefined || props.longitude === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Map provider={osm} center={[lat, long]} defaultZoom={5}>
      <Marker width={50} anchor={[lat, long]} />
    </Map>
  );
}
