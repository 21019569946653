import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import './Volcano.css';

import { MyContext } from '../Utils/myCtx';
import { useContext } from 'react';
import { VolcanoSearchBox } from './VolcanoSearchBox';

import { Badge } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';

export default function VolcanoList() {
  const navigate = useNavigate();

  const {
    selectedDistance,
    selectedCountry,
    countryVolcanoResults,
    volcanoId,
    setVolcanoId,
    searchedCountry,
    agGridCurrentPage,
    setAgGridCurrentPage,
    isAuthenticated,
  } = useContext(MyContext);

  const columns = [
    { headerName: `Name`, field: `name` },
    { headerName: `Region`, field: `region` },
    { headerName: `Subregion`, field: `subregion` },
  ];

  const visitVolcano = e => {
    if (e.data.id !== volcanoId) {
      setVolcanoId(e.data.id);
    }
    navigate(`/VolcanoDetails/?title=${e.data.name}&Id=${e.data.id}`);
  };

  return (
    <div className="query-results">
      <div className="returned-country-results">
        <div className="returned-country-information-container">
          <div className="returned-country-information-A">
            {searchedCountry ? (
              <h4 aria-live="assertive">
                <Badge
                  bg="success"
                  aria-live="polite"
                  aria-label={`${countryVolcanoResults.length}`}
                >
                  {countryVolcanoResults.length}
                </Badge>
                {countryVolcanoResults.length === 1
                  ? ' Volcano '
                  : ' Volcanoes '}
                associated with {selectedCountry}
                {selectedDistance !== 0
                  ? ` populated within ${selectedDistance} kms.`
                  : `.`}
              </h4>
            ) : (
              <h4>No country has been selected... </h4>
            )}
          </div>
          <div className="returned-country-information-B">
            {!searchedCountry ? (
              <p className="returned-country-information-B-item1">
                Please select a country to find volcanoes.
              </p>
            ) : isAuthenticated ? (
              <p className="returned-country-information-B-item1">
                Select a volcano to show detailed information.
              </p>
            ) : (
              <p className="returned-country-information-B-item1">
                Login or register to leave reviews.
              </p>
            )}

            <div className="returned-country-information-B-item2">
              <VolcanoSearchBox />
            </div>
          </div>
        </div>

        <div className="ag-grid.container">
          <div
            className="ag-theme-quartz"
            style={{ height: '590px', width: '620px' }}
            aria-label="Table of available volcanoes for selection"
          >
            {countryVolcanoResults ? (
              <AgGridReact
                columnDefs={columns}
                rowData={countryVolcanoResults}
                pagination={true}
                paginationPageSize={11}
                onPaginationChanged={e => {
                  setAgGridCurrentPage(e.api.paginationGetCurrentPage());
                }}
                onRowClicked={visitVolcano}
                onGridReady={e => {
                  e.api.paginationGoToPage(agGridCurrentPage);
                }}
                onRowDataUpdated={e => {
                  e.api.paginationGoToPage(0);
                }}
                loadingMessage="Please select a country."
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
