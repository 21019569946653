import { MyContext } from './myCtx';
import { useContext } from 'react';
import { Button, Modal, Badge } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../Auth/Admin.css';

export function NotificationModal() {
  const navigate = useNavigate();
  const { clientErrorMessage, isAuthenticated, setIsAuthenticated } =
    useContext(MyContext);

  return (
    <div>
      <Modal
        size="md"
        centered
        show={clientErrorMessage}
        animation={true}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>
            <Badge bg="danger">Oh no!</Badge>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>{clientErrorMessage}</p>
          <p>Please restart your session and try again.</p>
        </Modal.Body>
        <Modal.Footer>
          {isAuthenticated ? (
            <Button
              variant="dark"
              className="login-button btn-lg"
              type="submit"
              onClick={() => {
                navigate('/Login');
                setIsAuthenticated(false);
              }}
            >
              Log in now!
            </Button>
          ) : (
            <Button
              variant="dark"
              className="login-button btn-lg"
              type="submit"
              onClick={() => {
                navigate('/');
                setIsAuthenticated(false);
              }}
            >
              Home.
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
