import './Admin.css';
import { Form, FormGroup, FormFeedback, Label, Input } from 'reactstrap';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { authenticateUser, isEmailValid } from '../Utils/authentication';
import { MyContext } from '../Utils/myCtx';
import { AuthenticationModal } from '../Utils/AuthenticationModal';
import { useContext, useState } from 'react';

export default function Login() {
  const navigate = useNavigate();

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');

  const {
    setAuthenticationError,
    credentialError,
    setCredentialError,
    setCredentialMessage,
    emailError,
    setEmailError,
    authenticationMessage,
    setAuthenticationMessage,
    setIsAuthenticated,
    isAuthenticated,
  } = useContext(MyContext);

  return (
    <div className="login-form-container">
      <Form
        className="login-form"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        {authenticationMessage ? <AuthenticationModal /> : <></>}

        {isAuthenticated ? (
          <h1 className="login-greeting">You're already logged in!</h1>
        ) : loginEmail ? (
          <h1 className="login-greeting">Welcome back {loginEmail}</h1>
        ) : (
          <h1 className="login-greeting">Login.</h1>
        )}

        <FormGroup>
          <Label for="Email">Email</Label>
          <Input
            invalid={emailError !== null || credentialError !== null}
            type="email"
            name="email"
            id="Email"
            placeholder="Enter email"
            value={loginEmail}
            onChange={(e) => {
              isEmailValid(
                e,
                setAuthenticationError,
                setLoginEmail,
                setEmailError
              );
            }}
          />

          {
            <FormFeedback>
              {emailError ||
                (credentialError && 'Email / Password combination invalid.')}
            </FormFeedback>
          }
        </FormGroup>

        <FormGroup>
          <Label for="Password">Password</Label>
          <Input
            invalid={credentialError !== null}
            type="password"
            name="password"
            id="Password"
            placeholder="Enter password"
            value={loginPassword}
            onChange={(e) => {
              setCredentialError(null);
              setAuthenticationError(null);
              setEmailError(null);
              setLoginPassword(e.target.value);
            }}
          />

          {<FormFeedback>Email / Password combination invalid.</FormFeedback>}
        </FormGroup>

        <div className="login-button-wrapper">
          <Button
            variant="dark"
            className="login-button btn-lg"
            disabled={
              emailError !== null || credentialError !== null || isAuthenticated
            }
            type="submit"
            onClick={() => {
              authenticateUser(
                `login`,
                loginEmail,
                loginPassword,
                setAuthenticationError,
                setAuthenticationMessage,
                setIsAuthenticated,
                setCredentialError,
                setCredentialMessage
              );
            }}
          >
            Log in
          </Button>
        </div>

        <div className="login-button-wrapper">
          <Button
            variant="secondary"
            className="login-button btn-lg"
            type="submit"
            onClick={() => {
              navigate('/Register');
            }}
          >
            Register
          </Button>
        </div>
      </Form>
    </div>
  );
}
